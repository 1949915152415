<template>
  <UiIconBase>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8343 8.69636C14.906 7.76798 13.4008 7.76788 12.4723 8.69605C12.0818 9.0864 11.4489 9.0864 11.0584 8.69605C10.1299 7.76788 8.62474 7.76798 7.69636 8.69636C6.76793 9.62479 6.76788 11.13 7.69621 12.0585C7.70224 12.0646 7.70817 12.0707 7.71402 12.0768L11.7654 16.1281L15.8167 12.0768C15.8226 12.0706 15.8285 12.0646 15.8345 12.0585C16.7628 11.13 16.7628 9.62479 15.8343 8.69636ZM17.2693 13.452C18.9581 11.7408 18.9511 8.98472 17.2486 7.28215C15.7588 5.79241 13.4625 5.60088 11.7654 6.70754C10.0682 5.60088 7.77188 5.79241 6.28215 7.28215C4.57958 8.98471 4.57265 11.7408 6.26133 13.452C6.268 13.459 6.27479 13.466 6.2817 13.4729L11.0582 18.2495C11.4488 18.64 12.0819 18.64 12.4725 18.2495L17.249 13.4729C17.2559 13.466 17.2627 13.459 17.2693 13.452Z"
      fill="#0038FF"
    />
  </UiIconBase>
</template>
