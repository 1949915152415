<script setup>
import useMyCollegeService from 'share/services/college/useMyCollegeService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import useCollegeSurveyResultService from 'share/services/college/useCollegeSurveyResultService'
import { storeToRefs } from 'pinia'

definePageMeta({
  keepalive: true,
})

const { isLoggedIn } = storeToRefs(useMemberSessionStore())

const { myCollegeList, fetchMyCollegeList, handleAddMyCollege, handleDeleteMyCollege } = useMyCollegeService()

const { CollegeSurveyResultViewMore, fetchCollegeSurveyData, fetchCollegeSurveyResultViewMore } =
  useCollegeSurveyResultService()

const tabHeads = ref([
  {
    label: 'Reach',
    value: 'reach',
    count: CollegeSurveyResultViewMore.value.reachCollegeCnt,
  },
  {
    label: 'Target',
    value: 'target',
    count: CollegeSurveyResultViewMore.value.targetCollegeCnt,
  },
  {
    label: 'Safety',
    value: 'safety',
    count: CollegeSurveyResultViewMore.value.safetyCollegeCnt,
  },
])

const activeTab = ref('reach')

const changeTab = (tab) => {
  activeTab.value = tab
}

onMounted(async () => {
  console.log(tabHeads)

  if (isLoggedIn.value) {
    fetchMyCollegeList()
    // await fetchCollegeSurveyData()
    await fetchCollegeSurveyResultViewMore()

    const surveyData = CollegeSurveyResultViewMore.value

    if (surveyData) {
      tabHeads.value.forEach((tab) => {
        const countMap = {
          reach: 'reachCollegeCnt',
          target: 'targetCollegeCnt',
          safety: 'safetyCollegeCnt',
        }

        tab.count = surveyData[countMap[tab.value]] || 0
      })
    }
  }
})
</script>

<template>
  <div style="display: flex; flex-direction: column; gap: 16px; flex: 1 0 0; align-self: stretch">
    <div class="flex position-relative gap-4">
      <UiTab
        v-for="tab in tabHeads"
        :key="tab.value"
        class="p-x-12 p-y-8"
        :class="{ active: activeTab === tab.value }"
        @click="changeTab(tab.value)"
      >
        <div class="flex gap-8 items-start">
          {{ tab.label }}
          <div
            class="flex text-caption2-b w-22 gap-10 items-center"
            style="padding: 2px 3px; justify-content: center; border-radius: 99px; background: #f5f7ff"
          >
            {{ tab.count }}
          </div>
        </div>
      </UiTab>
      <UiSeparator class="position-absolute bottom-0 left-0 bg-gray2" style="height: 2px" />
    </div>

    <div class="flex stretch">
      <div class="current-college-title m-bottom-16" v-if="activeTab === 'reach'">
        <div class="flex flex-direction-column items-start gap-12 stretch" style="padding: 1px">
          <ul
            v-for="(college, index) in CollegeSurveyResultViewMore.reachCollegeList"
            :key="college.ipedsCollegeId"
            class="recommended-collegelist radius-8 item-shadow cursor-pointer"
          >
            <div class="recommended-card" @click.stop="navigateTo(`/college/detail/${college.ipedsCollegeId}`)">
              <div
                class="w-64 h-64 radius-8 bg-no-repeat flex bg-size-cover bg-position-center"
                style="width: 64px; height: 64px; flex-shrink: 0; border-radius: 8px"
                :style="`background-image: url('${college.imgUrl}')`"
              ></div>
              <div class="college-card">
                <div
                  class="flex flex-direction-column justify-center flex-start gap-4"
                  style="flex: 1 0 0; min-width: 162px"
                >
                  <div class="flex items-center gap-4 stretch">
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Reach'" class="border purple small">
                      Reach
                    </UiLabel>
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Target'" class="border orange small">
                      Target
                    </UiLabel>
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Safety'" class="border mint small">
                      Safety
                    </UiLabel>
                    <div style="display: flex; align-items: center; gap: 8px">
                      <p class="my-chances">My Chances : {{ college.userChancesInfo.userChancesRate }}%</p>
                    </div>
                  </div>
                  <strong class="college-name">
                    {{ college.ipedsCollegeName }}
                  </strong>
                </div>
              </div>
              <div class="flex items-start flex-start gap-16 flex-wrap" style="min-width: 232px; flex: 1 0 0">
                <div class="flex flex-direction-column items-start justify-center gap-4">
                  <li
                    class="flex items-start text-caption1 text-t4 items-center flex-direction-column gap-4"
                    style="width: 142px"
                  >
                    <div style="display: flex; height: 20px; align-items: center; gap: 4px; flex-direction: row">
                      <p class="college-detail">SAT(ACT)</p>
                      <UiLabel v-if="college.adminTest === 1" class="border purple small">Required</UiLabel>
                      <UiLabel v-if="college.adminTest === 3" class="border orange small">Not Required</UiLabel>
                      <UiLabel v-if="college.adminTest === 5" class="border mint small">Optional</UiLabel>
                    </div>
                    <strong class="satact-range" v-if="college.satCompRange !== '' && college.actc !== ''">
                      {{ college.satCompRange }} ({{ college.actc }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange == '' && college.actc !== ''">
                      {{ '--' }} ({{ college.actc }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange !== '' && college.actc == ''">
                      {{ college.satCompRange }} ({{ '-' }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange == '' && college.actc == ''">
                      {{ 'No data available' }}
                    </strong>
                  </li>
                </div>
                <div class="flex flex-direction-column justify-center flex-start items-start gap-4">
                  <li
                    class="flex text-caption1 text-t4 flex items-center"
                    style="display: flex; align-items: center; gap: 4px; flex-direction: column"
                  >
                    <div style="display: flex; height: 20px; align-items: center; gap: 4px; flex-direction: row">
                      <p class="college-detail">Net Price</p>
                    </div>
                    <strong class="satact-range">${{ college.netPrice?.toLocaleString() || '-' }}</strong>
                  </li>
                </div>
              </div>
            </div>
            <div
              v-if="!myCollegeList.find((my) => my.ipedsCollegeId === college.ipedsCollegeId)"
              class="cursor-pointer flex wh-24 p-a-6 items-center justify-center"
              style="
                padding: 6px;
                border-radius: 999px;
                border: 2px solid var(--primary-primary1, #f5f7ff);
                background: var(--gray-white, #fff);
              "
            >
              <IconFavorite
                width="24"
                height="24"
                @click.stop="
                  isLoggedIn
                    ? handleAddMyCollege(college.ipedsCollegeId)
                    : navigateTo('/auth/sign-in?redirectUrl=/college/result')
                "
              />
            </div>
            <div
              v-else
              class="cursor-pointer flex wh-24 p-a-6 items-center justify-center"
              style="
                padding: 6px;
                border-radius: 999px;
                border: 2px solid var(--primary-primary1, #f5f7ff);
                background: var(--primary-primary1, #f5f7ff);
              "
            >
              <IconFavoriteActivated
                width="24"
                height="24"
                @click.stop="handleDeleteMyCollege(college.ipedsCollegeId)"
              />
            </div>
          </ul>
        </div>
      </div>

      <div class="current-college-title m-bottom-16" v-if="activeTab === 'target'">
        <div class="flex flex-direction-column items-start gap-12 stretch" style="padding: 1px">
          <ul
            v-for="(college, index) in CollegeSurveyResultViewMore.targetCollegeList"
            :key="college.ipedsCollegeId"
            class="recommended-collegelist radius-8 item-shadow cursor-pointer"
          >
            <div class="recommended-card" @click.stop="navigateTo(`/college/detail/${college.ipedsCollegeId}`)">
              <div
                class="w-64 h-64 radius-8 bg-no-repeat flex bg-size-cover bg-position-center"
                style="width: 64px; height: 64px; flex-shrink: 0; border-radius: 8px"
                :style="`background-image: url('${college.imgUrl}')`"
              ></div>
              <div class="college-card">
                <div
                  class="flex flex-direction-column justify-center flex-start gap-4"
                  style="flex: 1 0 0; min-width: 162px"
                >
                  <div class="flex items-center gap-4 stretch">
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Reach'" class="border purple small">
                      Reach
                    </UiLabel>
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Target'" class="border orange small">
                      Target
                    </UiLabel>
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Safety'" class="border mint small">
                      Safety
                    </UiLabel>
                    <div style="display: flex; align-items: center; gap: 8px">
                      <p class="my-chances">My Chances : {{ college.userChancesInfo.userChancesRate }}%</p>
                    </div>
                  </div>
                  <strong class="college-name">
                    {{ college.ipedsCollegeName }}
                  </strong>
                </div>
              </div>
              <div class="flex items-start flex-start gap-16 flex-wrap" style="min-width: 232px; flex: 1 0 0">
                <div class="flex flex-direction-column items-start justify-center gap-4">
                  <li
                    class="flex items-start text-caption1 text-t4 items-center flex-direction-column gap-4"
                    style="width: 142px"
                  >
                    <div style="display: flex; height: 20px; align-items: center; gap: 4px; flex-direction: row">
                      <p class="college-detail">SAT(ACT)</p>
                      <UiLabel v-if="college.adminTest === 1" class="border purple small">Required</UiLabel>
                      <UiLabel v-if="college.adminTest === 3" class="border orange small">Not Required</UiLabel>
                      <UiLabel v-if="college.adminTest === 5" class="border mint small">Optional</UiLabel>
                    </div>
                    <strong class="satact-range" v-if="college.satCompRange !== '' && college.actc !== ''">
                      {{ college.satCompRange }} ({{ college.actc }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange == '' && college.actc !== ''">
                      {{ '--' }} ({{ college.actc }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange !== '' && college.actc == ''">
                      {{ college.satCompRange }} ({{ '-' }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange == '' && college.actc == ''">
                      {{ 'No data available' }}
                    </strong>
                  </li>
                </div>
                <div class="flex flex-direction-column justify-center flex-start items-start gap-4">
                  <li
                    class="flex text-caption1 text-t4 flex items-center"
                    style="display: flex; align-items: center; gap: 4px; flex-direction: column"
                  >
                    <div style="display: flex; height: 20px; align-items: center; gap: 4px; flex-direction: row">
                      <p class="college-detail">Net Price</p>
                    </div>
                    <strong class="satact-range">${{ college.netPrice?.toLocaleString() || '-' }}</strong>
                  </li>
                </div>
              </div>
            </div>
            <div
              v-if="!myCollegeList.find((my) => my.ipedsCollegeId === college.ipedsCollegeId)"
              class="cursor-pointer flex wh-24 p-a-6 items-center justify-center"
              style="
                padding: 6px;
                border-radius: 999px;
                border: 2px solid var(--primary-primary1, #f5f7ff);
                background: var(--gray-white, #fff);
              "
            >
              <IconFavorite
                width="24"
                height="24"
                @click.stop="
                  isLoggedIn
                    ? handleAddMyCollege(college.ipedsCollegeId)
                    : navigateTo('/auth/sign-in?redirectUrl=/college/result')
                "
              />
            </div>
            <div
              v-else
              class="cursor-pointer flex wh-24 p-a-6 items-center justify-center"
              style="
                padding: 6px;
                border-radius: 999px;
                border: 2px solid var(--primary-primary1, #f5f7ff);
                background: var(--primary-primary1, #f5f7ff);
              "
            >
              <IconFavoriteActivated
                width="24"
                height="24"
                @click.stop="handleDeleteMyCollege(college.ipedsCollegeId)"
              />
            </div>
          </ul>
        </div>
      </div>

      <div class="current-college-title m-bottom-16" v-if="activeTab === 'safety'">
        <div class="flex flex-direction-column items-start gap-12 stretch" style="padding: 1px">
          <ul
            v-for="(college, index) in CollegeSurveyResultViewMore.safetyCollegeList"
            :key="college.ipedsCollegeId"
            class="recommended-collegelist radius-8 item-shadow cursor-pointer"
          >
            <div class="recommended-card" @click.stop="navigateTo(`/college/detail/${college.ipedsCollegeId}`)">
              <div
                class="w-64 h-64 radius-8 bg-no-repeat flex bg-size-cover bg-position-center"
                style="width: 64px; height: 64px; flex-shrink: 0; border-radius: 8px"
                :style="`background-image: url('${college.imgUrl}')`"
              ></div>
              <div class="college-card">
                <div
                  class="flex flex-direction-column justify-center flex-start gap-4"
                  style="flex: 1 0 0; min-width: 162px"
                >
                  <div class="flex items-center gap-4 stretch">
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Reach'" class="border purple small">
                      Reach
                    </UiLabel>
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Target'" class="border orange small">
                      Target
                    </UiLabel>
                    <UiLabel v-if="college.userChancesInfo.userChancesTitle === 'Safety'" class="border mint small">
                      Safety
                    </UiLabel>
                    <div style="display: flex; align-items: center; gap: 8px">
                      <p class="my-chances">My Chances : {{ college.userChancesInfo.userChancesRate }}%</p>
                    </div>
                  </div>
                  <strong class="college-name">
                    {{ college.ipedsCollegeName }}
                  </strong>
                </div>
              </div>
              <div class="flex items-start flex-start gap-16 flex-wrap" style="min-width: 232px; flex: 1 0 0">
                <div class="flex flex-direction-column items-start justify-center gap-4">
                  <li
                    class="flex items-start text-caption1 text-t4 items-center flex-direction-column gap-4"
                    style="width: 142px"
                  >
                    <div style="display: flex; height: 20px; align-items: center; gap: 4px; flex-direction: row">
                      <p class="college-detail">SAT(ACT)</p>
                      <UiLabel v-if="college.adminTest === 1" class="border purple small">Required</UiLabel>
                      <UiLabel v-if="college.adminTest === 3" class="border orange small">Not Required</UiLabel>
                      <UiLabel v-if="college.adminTest === 5" class="border mint small">Optional</UiLabel>
                    </div>
                    <strong class="satact-range" v-if="college.satCompRange !== '' && college.actc !== ''">
                      {{ college.satCompRange }} ({{ college.actc }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange == '' && college.actc !== ''">
                      {{ '--' }} ({{ college.actc }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange !== '' && college.actc == ''">
                      {{ college.satCompRange }} ({{ '-' }})
                    </strong>
                    <strong class="satact-range" v-else-if="college.satCompRange == '' && college.actc == ''">
                      {{ 'No data available' }}
                    </strong>
                  </li>
                </div>
                <div class="flex flex-direction-column justify-center flex-start items-start gap-4">
                  <li
                    class="flex text-caption1 text-t4 flex items-center"
                    style="display: flex; align-items: center; gap: 4px; flex-direction: column"
                  >
                    <div style="display: flex; height: 20px; align-items: center; gap: 4px; flex-direction: row">
                      <p class="college-detail">Net Price</p>
                    </div>
                    <strong class="satact-range">${{ college.netPrice?.toLocaleString() || '-' }}</strong>
                  </li>
                </div>
              </div>
            </div>
            <div
              v-if="!myCollegeList.find((my) => my.ipedsCollegeId === college.ipedsCollegeId)"
              class="cursor-pointer flex wh-24 p-a-6 items-center justify-center"
              style="
                padding: 6px;
                border-radius: 999px;
                border: 2px solid var(--primary-primary1, #f5f7ff);
                background: var(--gray-white, #fff);
              "
            >
              <IconFavorite
                width="24"
                height="24"
                @click.stop="
                  isLoggedIn
                    ? handleAddMyCollege(college.ipedsCollegeId)
                    : navigateTo('/auth/sign-in?redirectUrl=/college/result')
                "
              />
            </div>
            <div
              v-else
              class="cursor-pointer flex wh-24 p-a-6 items-center justify-center"
              style="
                padding: 6px;
                border-radius: 999px;
                border: 2px solid var(--primary-primary1, #f5f7ff);
                background: var(--primary-primary1, #f5f7ff);
              "
            >
              <IconFavoriteActivated
                width="24"
                height="24"
                @click.stop="handleDeleteMyCollege(college.ipedsCollegeId)"
              />
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@include fullHeight;

.current-college-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--gray-black, #17171b);
  text-overflow: ellipsis;

  /* sub title2-b */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 138.889% */
  letter-spacing: -0.36px;
}

.college-detail {
  overflow: hidden;
  color: var(--gray-gray5, #747474);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* caption2-r */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.048px;
}

.college-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  color: var(--gray-black, #17171b);
  text-overflow: ellipsis;

  /* body2-b */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.035px;
}

.recommended-collegelist {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
}

.recommended-card {
  display: flex;
  min-width: 232px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.college-card {
  display: flex;
  min-width: 200px;
  max-width: 400px;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}

.my-chances {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--gray-gray6, #434247);
  text-overflow: ellipsis;

  /* caption2-r */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.048px;
}

.college-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  color: var(--gray-black, #17171b);
  text-overflow: ellipsis;

  /* body2-b */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.035px;
}

.college-detail {
  overflow: hidden;
  color: var(--gray-gray5, #747474);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* caption2-r */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.048px;
}

.satact-range {
  overflow: hidden;
  color: var(--gray-gray6, #434247);
  text-overflow: ellipsis;

  /* body2-r */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.035px;
}
</style>