<template>
  <UiIconBase>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2693 13.452C18.9581 11.7408 18.9511 8.98472 17.2486 7.28215C15.7588 5.79241 13.4625 5.60088 11.7654 6.70754C10.0682 5.60088 7.77188 5.79241 6.28215 7.28215C4.57958 8.98471 4.57265 11.7408 6.26133 13.452C6.268 13.459 6.27479 13.466 6.2817 13.4729L11.0582 18.2495C11.4488 18.64 12.0819 18.64 12.4725 18.2495L17.249 13.4729C17.2559 13.466 17.2627 13.459 17.2693 13.452Z"
      fill="#0038FF"
    />
  </UiIconBase>
</template>
